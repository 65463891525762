
import { defineComponent } from 'vue';
import TheFieldsUpdate from '@/components/TheFieldsUpdate.vue';
import { fetchGoogleApiKey } from '@/services/fields';
import { MUTATION } from '@/store/mutations';
import { store } from '@/store/store';
import { ACTION } from '@/store/actions';
import ConfirmAction from '@/components/ConfirmAction.vue';
import { cleanDomFromGoogleMaps, CustomWindow } from '@/utils';
import { STATE } from '@/constants';
import { TUserAccess } from '@/store/state';

export default defineComponent({
    name: 'Fields',
    components: { TheFieldsUpdate, ConfirmAction },
    data() {
        return {
            googleMapsErr: false
        }
    },
    computed: {
        userPerm(): TUserAccess {
            return store.state.sessionInfo.perm
        },
        fieldNames(): string[] {
            return store.getters.sortedFields;
        },
        canEdit(): boolean {
           return store.getters.isAdmin;
        },
        fieldsLoading(): boolean {
            return store.state.fieldsRequestStatus.status === STATE.LOADING;
        },
        validApiKeys(): boolean {
            return store.state.googleMapsRequestStatus.status === STATE.DONE;
        },
        invalidApiKeys(): boolean {
            return store.state.googleMapsRequestStatus.status === STATE.ERR;
        },
        externalLink(): string {
            return store.getters.fieldExternalLinkUrl
        },
        externalLinkDisplayName(): string {
            return store.getters.fieldExternalLinkDisplayName;
        },
        showExternalLink(): boolean {
            return store.getters.fieldExternalLinkVisible;
        },
        googleApiKeyReady(): boolean {
            return store.state.googleMapsRequestStatus.status === STATE.DONE;
        }
    },
    methods: {
        initMaps() {
            this.$nextTick(() => {
            try {
                this.fieldNames.forEach(fieldName => {
                    const fieldInfo = store.getters.fieldInfo(fieldName);
                     // The location of Uluru
                    const coords = {
                        lat: fieldInfo.coordinates[0],
                        lng: fieldInfo.coordinates[1]
                    };
                    // The map, centered at Uluru
                    const map = new google.maps.Map( //eslint-disable-line
                        this.$refs[fieldName] as HTMLElement, {
                            zoom: 17,
                            center: coords,
                            gestureHandling: "cooperative",
                            mapTypeId: "roadmap",
                            mapTypeControl: false,
                            streetViewControl: false
                        }
                    );

                    // The marker, positioned at Uluru
                    new google.maps.Marker({ //eslint-disable-line
                        position: coords,
                        map: map
                    });
                });
                store.commit(MUTATION.GOOGLE_MAPS_REQUEST_STATUS, {
                    status: STATE.DONE, msg: ''
                });

                let showField = this.$router.currentRoute.value.hash as string;
                const parent = this.$refs.fields as HTMLElement;
                if(showField.length > 1 && parent) {
                    showField = decodeURI(showField.slice(1));
                    const fields = this.fieldNames;
                    let target = fields.findIndex((m, ix) => {
                        if(m === showField) return ix;
                    });
                    if(target === -1) target = 0;

                    const targetRow = parent.children[target] as HTMLElement;
                    if(!targetRow || targetRow.scrollIntoView === undefined) return;

                    targetRow.scrollIntoView();
                    targetRow.focus();
                }
            } catch (err) {
                store.commit(MUTATION.GOOGLE_MAPS_REQUEST_STATUS, {
                    status: STATE.ERR, msg: 'Google maps not loaded yet'
                });
            }});
        },
        updateGoogleScriptState() {
            store.commit(MUTATION.GOOGLE_MAPS_REQUEST_STATUS, {
                status: STATE.DONE, msg: ''
            });
        },
        async getGoogleMapsCode() {

                // Load JS library from google

            try {
                cleanDomFromGoogleMaps();
                const GOOGLE_API_KEY = await fetchGoogleApiKey();
                const script = document.createElement('script');
                script.setAttribute('type', "text/javascript");
                script.setAttribute('async', '');
                script.setAttribute('defer', '');
                script.setAttribute('src', 
                    'https://maps.googleapis.com/maps/api/js' +
                        `?key=${GOOGLE_API_KEY}` +
                        '&libraries=places' +
                        '&callback=updateGoogleScriptState' +
                        '&v=weekly'
                );
                store.commit(MUTATION.GOOGLE_MAPS_REQUEST_STATUS, {
                    status: STATE.LOADING, msg: ''
                });
                (window as unknown as CustomWindow).updateGoogleScriptState = this.updateGoogleScriptState.bind(this);
                (this.$refs.fields as HTMLDivElement).appendChild(script);
            } catch(err){
                store.commit(MUTATION.GOOGLE_MAPS_REQUEST_STATUS, {
                    status: STATE.ERR, msg: ''
                });
            }
        },
        removeField(fieldName: string) {
            const field = store.getters.fieldInfo(fieldName);
            store.dispatch(ACTION.REMOVE_FIELD, field._id);
        },
        deleteError(fieldName: string): boolean {
            const field = store.getters.fieldInfo(fieldName);
            return store.state.removeFieldRequestStatus[field._id]?.status === STATE.ERR;
        },
        deleteErrorMsg(fieldName: string): string {
            const field = store.getters.fieldInfo(fieldName);
            return store.state.removeFieldRequestStatus[field._id]?.msg;
        }
    },
    async mounted() {
        if(store.state.fieldsRequestStatus.status === STATE.STALE) {
            store.dispatch(ACTION.FETCH_FIELDS);
        }
        this.getGoogleMapsCode();
    },
    watch: {
        fieldNames() {
            if (this.googleApiKeyReady) this.initMaps();
        },
        googleApiKeyReady(ready: boolean) {
            if(ready) {
                this.initMaps();
            }
        },
        userPerm() {
            this.getGoogleMapsCode();
        }
    }
});
