
import { defineComponent } from 'vue';
import { store } from '@/store/store';
import { ACTION } from '@/store/actions';
import { FIELD_TYPE, STATE } from '@/constants';

const DEFAULT_PLACE = [45.5017, -73.5673]; // Montreal

export default defineComponent({
    name: 'Schedule',
    components: { },
    data() {
        return {
            addFieldName: '',
            addFieldPlace: null as unknown as google.maps.GeocoderAddressComponent[],
            addFieldTypeSelected: FIELD_TYPE.OUTDOOR,
            addFieldCoordsLat: DEFAULT_PLACE[0],
            addFieldCoordsLng: DEFAULT_PLACE[1],
            updateMapTimer: 0,
            previewMap: null as unknown as google.maps.Map,
            previewMarker: null as unknown as google.maps.Marker,
            previewAutocomplete: null as unknown as google.maps.places.Autocomplete,
            previewGeocoder: null as unknown as google.maps.Geocoder,
        };
    },
    computed: {
        fieldAddValid(): boolean {
            const {city, country, state} = this.extractCityFromPlace();
            return (
                this.addFieldName !== ''
                && (
                    [city, country, state].every(s => s !== '')
                    || !(
                        this.addFieldCoordsLat === DEFAULT_PLACE[0]
                        && this.addFieldCoordsLat === DEFAULT_PLACE[1]
                    )
                )
                && Object.values(FIELD_TYPE).indexOf(this.addFieldTypeSelected) !== -1
            );
        },
        fieldTypes(): string[] {
            return Object.values(FIELD_TYPE);
        },
        validApiKeys(): boolean {
            return store.state.googleMapsRequestStatus.status !== STATE.ERR;
        },
        googleApiKeyReady(): boolean {
            return store.state.googleMapsRequestStatus.status === STATE.DONE;
        },
        canLoadGoogleMaps(): boolean {

                // Needed because loading twice the code may be problematic.
                // Ensure code is loaded after the first maps are loaded

            return store.state.googleMapsRequestStatus.status === STATE.DONE;
        }
    },
    watch: {
        googleApiKeyReady(ready: boolean) {
            if(ready) {
                this.initMaps();
            }
        }
    },
    methods: {
        initMaps() {
            this.$nextTick(() => {
            try {
                const coords = {
                    lat: this.addFieldCoordsLat,
                    lng: this.addFieldCoordsLng
                };
                this.previewMap = new google.maps.Map( //eslint-disable-line
                    this.$refs['preview'] as HTMLElement, {
                        zoom: 12,
                        center: coords,
                        gestureHandling: "cooperative",
                        mapTypeId: "roadmap",
                        mapTypeControl: false,
                        streetViewControl: false
                    }
                );

                this.previewGeocoder = new google.maps.Geocoder(); //eslint-disable-line

                // The marker, positioned at Uluru
                this.previewMarker = new google.maps.Marker({ //eslint-disable-line
                    draggable: true,
                    position: coords,
                    map: this.previewMap
                });

                this.previewAutocomplete = new google.maps.places.Autocomplete( //eslint-disable-line
                    this.$refs['places'] as HTMLInputElement
                );
                this.previewAutocomplete.bindTo("bounds", this.previewMap);
                this.previewAutocomplete.setFields([
                    "place_id", "geometry", "name", "formatted_address"
                ]);
                this.previewAutocomplete.addListener("place_changed", () => {
                    this.updateMapsFromPlace();
                });
                google.maps.event.addListener(this.previewMarker, 'dragend', () => { //eslint-disable-line
                    const pos = this.previewMarker.getPosition();
                    if (pos) {
                        this.previewGeocoder.geocode({location: pos}, (results, status) => {
                            if (status == google.maps.GeocoderStatus.OK && results) { //eslint-disable-line
                                this.addFieldCoordsLng = pos.lng();
                                this.addFieldCoordsLat = pos.lat();
                                this.addFieldPlace = results[0].address_components;
                            }
                        });
                    }
                });
            } catch (err) {
                console.error(err);
            }});
        },
        updateMapsFromPlace() {
            if(!this.previewMap) return;
            const map = this.previewMap;
            const marker = this.previewMarker;
            const place = this.previewAutocomplete.getPlace();
            

            if (!place?.place_id) return;

            this.previewGeocoder.geocode(
                { placeId: place.place_id },
                (results, status) => {
                    if (status !== "OK" || !results) return;

                    map.setCenter(results[0].geometry.location);
                    marker.setPosition(results[0].geometry.location);

                    this.addFieldCoordsLng = results[0].geometry.location.lng();
                    this.addFieldCoordsLat = results[0].geometry.location.lat();
                    this.addFieldPlace = results[0].address_components;
                }
            );
        },
        updateMapsFromLatLng() {
            if(!this.previewMap) return;
            clearTimeout(this.updateMapTimer);
            this.updateMapTimer = setTimeout(() => {
                const map = this.previewMap;
                const marker = this.previewMarker;
                const latlng = {
                    lat: parseFloat(this.addFieldCoordsLat.toString()),
                    lng: parseFloat(this.addFieldCoordsLng.toString())
                };
                this.previewMarker.setPosition(latlng);
                this.previewGeocoder.geocode(
                    {location: latlng},
                    (results, status) => {
                        if (status !== "OK" || !results) return;
                        map.setCenter(results[0].geometry.location);
                        marker.setPosition(results[0].geometry.location);
                        this.addFieldCoordsLng = results[0].geometry.location.lng();
                        this.addFieldCoordsLat = results[0].geometry.location.lat();
                        this.addFieldPlace = results[0].address_components;
                    }
                );
            }, 3000);
        },
        extractCityFromPlace(): {city: string, country: string, state: string} {
            const place = {
                city: '',
                country: '',
                state: ''
            }
            if(!this.addFieldPlace) return place;

            for (let j = 0 ; j < this.addFieldPlace.length ; ++j) {
                const lTypes = this.addFieldPlace[j].types;
                for (let k = 0 ; k < lTypes.length ; ++k) {
                    //find city
                    if (lTypes[k] == "locality"){
                        place.city = this.addFieldPlace[j].long_name.toLowerCase();
                    }
                    //find county
                    if (lTypes[k] == "country") {
                        place.country = this.addFieldPlace[j].long_name.toLowerCase();
                    }
                    //find State
                    if (lTypes[k] == "administrative_area_level_1") {
                        place.state = this.addFieldPlace[j].short_name.toLowerCase();
                    }
                }
            }
            return place;
        },
        addField() {
            if(!this.fieldAddValid) return;

            // Read data;

            const name = this.addFieldName;
            const type = this.addFieldTypeSelected;
            const coordinates = [
                this.addFieldCoordsLat, this.addFieldCoordsLng
            ] as [number, number];

            // Add field

            const {city, country, state} = this.extractCityFromPlace();
            store.dispatch(ACTION.ADD_FIELD, {
                name,
                coordinates,
                type,
                city,
                country,
                state
            });

            // Reset values (but don't reset city/state/country)

            this.addFieldName = '';
            this.addFieldTypeSelected = FIELD_TYPE.OUTDOOR;
            this.addFieldCoordsLat = 0;
            this.addFieldCoordsLng = 0;
        }
    }
});
